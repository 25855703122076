import React, { useState, useEffect } from 'react';
import './App.css';

interface Experience {
  id: string;
  name: string;
  logo: string;
  description: string;
  action: string;
  backgroundImage: string;
  link: string;
}

// New interface for API response
interface ExperienceState {
  pageState: 'coming_soon' | 'experience_done' | 'experiences_showing';
  experiences: { name: string; enabled: boolean }[];
  timestamp: string;
}

const experiences: Experience[] = [
  {
    id: '1',
    name: 'Core Food Menu',
    logo: 'coreLogo.jpg',
    description: 'The new way to customize your food menu. Try it now!',
    action: 'Activate Experience',
    backgroundImage: 'restaurant.jpg',
    link: 'https://droppdemo.com'
  },
  {
    id: '2',
    name: 'Meet the KNIGHT - Al Nassr\'s New Official Mascot!',
    logo: 'nassrLogo.png',
    description: 'Click below to experience the Mascot in 3D AR on your smartphone!',
    action: 'Activate Experience',
    backgroundImage: 'stadium.png',
    link: 'https://alnassr.droppdemo.com'
  }
];

function ExperienceCard({ experience }: { experience: Experience }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    // Reset the animation after it completes
    setTimeout(() => setIsClicked(false), 300);
    // Open the link in a new tab
    window.open(experience.link, '_blank');
  };

  return (
    <div className="experience-card">
      <div className="experience-image" style={{ backgroundImage: `url(${experience.backgroundImage})` }}></div>
      <div className="experience-content">
        <div className="experience-logo">
          <img src={experience.logo} alt={experience.name} />
        </div>
        <h2>{experience.name}</h2>
        <p>{experience.description}</p>
        <button 
          className={`activate-button ${isClicked ? 'clicked' : ''}`} 
          onClick={handleClick}
        >
          {experience.action}
        </button>
      </div>
    </div>
  );
}

function App() {
  const [experienceState, setExperienceState] = useState<ExperienceState | null>(null);

  useEffect(() => {
    fetchExperienceState();

    // Set up polling every 5 seconds
    const intervalId = setInterval(fetchExperienceState, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const fetchExperienceState = async () => {
    try {
      const response = await fetch('https://api.droppdemo.com/experience-state');
      const data: ExperienceState = await response.json();
      setExperienceState(data);
    } catch (error) {
      console.error('Error fetching experience state:', error);
    }
  };

  const renderContent = () => {
    if (!experienceState) {
      return null;
    }

    switch (experienceState.pageState) {
      case 'coming_soon':
        return <ComingSoon />;
      case 'experience_done':
        return <ThankYou />;
      case 'experiences_showing':
        return (
          <main className="experience-grid">
            {experiences
              .filter((_, index) => experienceState.experiences[index]?.enabled)
              .map(exp => (
                <ExperienceCard key={exp.id} experience={exp} />
              ))}
          </main>
        );
      default:
        return null;
    }
  };

  return (
    <div className="App">
      <div className="top-banner"></div>
      <header className="App-header">
        <img src="droppLogo.png" alt="dropp logo" className="dropp-logo" />
      </header>
      {renderContent()}
    </div>
  );
}

function ComingSoon() {
  return (
    <div className="fullscreen-message">
      <h1>Coming Soon</h1>
      <p>Get ready for an amazing experience!</p>
    </div>
  );
}

function ThankYou() {
  return (
    <div className="fullscreen-message thank-you">
      <h1>Thank You</h1>
      <p>We hope you enjoyed the experience!</p>
      <div className="animation-container">
        <div className="checkmark-animation"></div>
      </div>
    </div>
  );
}

export default App;
